import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthUseCaseService } from '../../../auth/application/auth-use-case.service';

export const isLoguedGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthUseCaseService);
  const router = inject(Router);

  if (authService.hasToken && authService.sessionActive) {
    router.navigate(['/main']);
    return false;
  }

  return true;
};
