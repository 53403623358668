import { InjectionToken } from '@angular/core';
import { VacantApplicationApiService } from './vacant-application-api.service';

export const HTTP_VACANT_APPLICATION_SERVICE = new InjectionToken(
  'VacantApplicationApiService'
);

export const VACANT_APPLICATION_PROVIDER = {
  provide: HTTP_VACANT_APPLICATION_SERVICE,
  useClass: VacantApplicationApiService,
};
