import { inject, Injectable } from '@angular/core';
import { IVacantApplicationApiService } from './vacant-application-api.interface';
import { map, Observable } from 'rxjs';
import {
  VacantApplication,
  UpdateVacantApplication,
  VacantApplicationWithVacant,
  Vacant,
} from '../domain/VacantApplication.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { VacantApplicationWithVacantApiModel } from './vacant-application-api.model';

@Injectable({
  providedIn: 'root',
})
export class VacantApplicationApiService
  implements IVacantApplicationApiService
{
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  _mapToVacantApplication({
    vacantApplication,
    vacant,
  }: VacantApplicationWithVacantApiModel): VacantApplicationWithVacant {
    let vacantApplicationNew: VacantApplication = {
      id: vacantApplication.ID,
      createdAt: vacantApplication.CreatedAt,
      updatedAt: vacantApplication.UpdatedAt,
      vacantId: vacantApplication.vacantId,
      email: vacantApplication.email,
      firstName: vacantApplication.firstName,
      lastName: vacantApplication.lastName,
      phone: vacantApplication.phone,
      resume: vacantApplication.resume,
      cv: vacantApplication.cv,
      status: vacantApplication.status,
      lastContacted: vacantApplication.lastContacted,
      interviewDate: vacantApplication.interviewDate,
      rejectionReason: vacantApplication.rejectionReason,
      notes: vacantApplication.notes,
      recruiter: vacantApplication.recruiter,
    };

    let vacantNew: Vacant = {
      id: vacant.ID,
      createdAt: vacant.CreatedAt,
      updatedAt: vacant.UpdatedAt,
      company: vacant.companyId,
      position: vacant.position,
      department: vacant.department,
      minAge: vacant.minAge,
      maxAge: vacant.maxAge,
      requiredEducation: vacant.requiredEducation,
      skills: vacant.skills,
      offer: vacant.offer,
      image: vacant.image,
      isActive: vacant.isActive,
      createdBy: vacant.createdBy,
      updatedBy: vacant.updatedBy,
    };

    return {
      vacantApplication: vacantApplicationNew,
      vacant: vacantNew,
    };
  }

  _mapToVacantApplications(
    vacantApplications: VacantApplicationWithVacantApiModel[]
  ): VacantApplicationWithVacant[] {
    return vacantApplications.map((vacantApplication) =>
      this._mapToVacantApplication(vacantApplication)
    );
  }

  getAllVacantApplications(): Observable<VacantApplicationWithVacant[]> {
    const url = `${this.BASE_URL}vacant-applications`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      map((response) => {
        if (!response.results) return [];
        return this._mapToVacantApplications(
          response.results as VacantApplicationWithVacantApiModel[]
        );
      })
    );
  }

  getVacantApplicationById(
    id: number
  ): Observable<VacantApplicationWithVacant> {
    const url = `${this.BASE_URL}vacant-applications/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .get<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapToVacantApplication(
            response.results as VacantApplicationWithVacantApiModel
          )
        )
      );
  }

  getVacantApplicationByStatus(
    status: string
  ): Observable<VacantApplicationWithVacant[]> {
    const url = `${this.BASE_URL}vacant-applications-status/${status}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      map((response) => {
        if (!response.results) return [];
        return this._mapToVacantApplications(
          response.results as VacantApplicationWithVacantApiModel[]
        );
      })
    );
  }

  updateVacantApplication(
    id: number,
    vacantApplication: UpdateVacantApplication
  ): Observable<VacantApplicationWithVacant> {
    const url = `${this.BASE_URL}vacant-applications/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .put<ResponseApi>(url, vacantApplication, { headers })
      .pipe(
        map((response) =>
          this._mapToVacantApplication(
            response.results as VacantApplicationWithVacantApiModel
          )
        )
      );
  }

  deleteVacantApplication(id: number): Observable<VacantApplicationWithVacant> {
    const url = `${this.BASE_URL}vacant-applications/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .delete<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapToVacantApplication(
            response.results as VacantApplicationWithVacantApiModel
          )
        )
      );
  }
}
