import { CreateOrUpdateVacant } from '../domain/CreateOrUpdateVacant.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IVacantApiService } from './vacant-api.interface';
import { map, Observable } from 'rxjs';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { Vacant } from '../domain/Vacant.model';
import { VacantApiModel } from './models/vacant-api.model';

@Injectable({
  providedIn: 'root',
})
export class VacantApiService implements IVacantApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  private _mapVacantApiToVacant(vacantApi: VacantApiModel): Vacant {
    return {
      id: vacantApi.ID,
      createdAt: vacantApi.CreatedAt,
      updatedAt: vacantApi.UpdatedAt,
      company: vacantApi.companyId,
      position: vacantApi.position,
      department: vacantApi.department,
      minAge: vacantApi.minAge,
      maxAge: vacantApi.maxAge,
      requiredEducation: vacantApi.requiredEducation,
      skills: vacantApi.skills,
      offer: vacantApi.offer,
      // image: vacantApi.image,
      isActive: vacantApi.isActive,
      createdBy: vacantApi.createdBy,
      updatedBy: vacantApi.updatedBy,
    };
  }

  private _mapVacantsApiToVacants(vacantsApi: VacantApiModel[]): Vacant[] {
    return vacantsApi.map((vacantApi) => this._mapVacantApiToVacant(vacantApi));
  }

  getAllVacants(): Observable<Vacant[]> {
    const url = `${this.BASE_URL}vacants`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      map((response) => {
        if (!response.results) return [];
        return this._mapVacantsApiToVacants(
          response.results as VacantApiModel[]
        );
      })
    );
  }

  getVacantById(id: number): Observable<Vacant> {
    const url = `${this.BASE_URL}vacants/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .get<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapVacantApiToVacant(response.results as VacantApiModel)
        )
      );
  }

  activateVacant(id: number): Observable<Vacant> {
    const url = `${this.BASE_URL}vacants-activate/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .get<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapVacantApiToVacant(response.results as VacantApiModel)
        )
      );
  }

  deactivateVacant(id: number): Observable<Vacant> {
    const url = `${this.BASE_URL}vacants-deactivate/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .get<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapVacantApiToVacant(response.results as VacantApiModel)
        )
      );
  }

  createVacant(vacant: CreateOrUpdateVacant): Observable<Vacant> {
    const url = `${this.BASE_URL}vacants`;
    const headers = new HttpHeaders();
    return this._http
      .post<ResponseApi>(url, vacant, { headers })
      .pipe(
        map((response) =>
          this._mapVacantApiToVacant(response.results as VacantApiModel)
        )
      );
  }

  updateVacant(id: number, vacant: CreateOrUpdateVacant): Observable<Vacant> {
    const url = `${this.BASE_URL}vacants/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .put<ResponseApi>(url, vacant, { headers })
      .pipe(
        map((response) =>
          this._mapVacantApiToVacant(response.results as VacantApiModel)
        )
      );
  }

  deleteVacant(id: number): Observable<Vacant> {
    const url = `${this.BASE_URL}vacants/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .delete<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapVacantApiToVacant(response.results as VacantApiModel)
        )
      );
  }

  // merge
}
