import { InjectionToken } from "@angular/core";
import { TireApiService } from "../tire-api.service";


export const HTTP_TIRE_SERVICE = new InjectionToken(
  'TireApiService'
);

export const TIRE_PROVIDER = {
  provide: HTTP_TIRE_SERVICE,
  useClass: TireApiService
}
