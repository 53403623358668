import { inject, Injectable } from '@angular/core';
import { ISealTypeApiService } from './seal-type-api.interface';
import { map, Observable, take } from 'rxjs';
import { SealType } from '../domain/SealType.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { SealTypeApiModel } from './models/seal-type-api.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';

@Injectable({
  providedIn: 'root',
})
export class SealTypeApiService implements ISealTypeApiService {
  private _http = inject(HttpClient);
  private readonly BASE_URL = environment.baseUrl;

  private _setMapToSealType(sealType: SealTypeApiModel): SealType {
    return {
      id: sealType.ID,
      createdAt: sealType.CreatedAt,
      updatedAt: sealType.UpdatedAt,
      name: sealType.name,
    };
  }

  private _setMapToSealTypes(sealTypes: SealTypeApiModel[]): SealType[] {
    return sealTypes.map((sealType) => this._setMapToSealType(sealType));
  }

  getSealTypes(): Observable<SealType[]> {
    const url = `${this.BASE_URL}seal-types`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapToSealTypes(response.results as SealTypeApiModel[]);
      })
    );
  }

  getSealType(id: number): Observable<SealType> {
    const url = `${this.BASE_URL}seal-types/${id}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapToSealType(response.results as SealTypeApiModel);
      })
    );
  }

  createSealType(sealType: { name: string }): Observable<SealType> {
    const url = `${this.BASE_URL}seal-types`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, sealType, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapToSealType(response.results as SealTypeApiModel);
      })
    );
  }

  updateSealType(id: number, sealType: { name: string }): Observable<SealType> {
    const url = `${this.BASE_URL}seal-types/${id}`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, sealType, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapToSealType(response.results as SealTypeApiModel);
      })
    );
  }

  deleteSealType(id: number): Observable<boolean> {
    const url = `${this.BASE_URL}seal-types/${id}`;
    const headers = new HttpHeaders();
    return this._http.delete<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => response.ok)
    );
  }
}
