import { InjectionToken } from '@angular/core';
import { DepartmentsApiService } from '../departments-api.service';

export const HTTP_DEPARTMENT_SERVICE = new InjectionToken(
  'DepartmentsApiService'
);

export const DEPARTMENT_PROVIDER = {
  provide: HTTP_DEPARTMENT_SERVICE,
  useClass: DepartmentsApiService,
};
