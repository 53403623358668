import { Injectable, inject } from '@angular/core';
import { ITireApiService } from './tire-api.interface';
import { Observable, map, take } from 'rxjs';
import { Tire } from '../domain/Tire.model';
import { TireCreatedOrUpdate } from '../domain/TireCreatedOrUpdate.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TireApiModel } from './models/tire-api.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { TireUpdated } from '../domain/TireUpdated.model';

@Injectable({
  providedIn: 'root',
})
export class TireApiService implements ITireApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  private _setMapTires(tires: TireApiModel[]): Tire[] {
    return tires.map((tire) => {
      return {
        id: tire.ID,
        createdAt: tire.CreatedAt,
        depth: tire.depth,
        atPosition: tire.atPosition,
        idTireBrand: tire.idTireBrand,
        idTireState: tire.idTireState,
        idTrailer: tire.idTrailer,
        idTruck: tire.idTruck,
      };
    });
  }

  private _setMapTire(tire: TireApiModel): Tire {
    return {
      id: tire.ID,
      createdAt: tire.CreatedAt,
      depth: tire.depth,
      atPosition: tire.atPosition,
      idTireBrand: tire.idTireBrand,
      idTireState: tire.idTireState,
      idTrailer: tire.idTrailer,
      idTruck: tire.idTruck,
    };
  }

  getTires(): Observable<Tire[]> {
    const url = `${this.BASE_URL}tire`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapTires(response.results as TireApiModel[]);
      })
    );
  }

  getTiresByTruck(idTruck: number): Observable<Tire[]> {
    const url = `${this.BASE_URL}tire/truck/${idTruck}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapTires(response.results as TireApiModel[]);
      })
    );
  }

  getTiresByTrailer(idTrailer: number): Observable<Tire[]> {
    const url = `${this.BASE_URL}tire/trailer/${idTrailer}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapTires(response.results as TireApiModel[]);
      })
    );
  }

  getTireById(id: number): Observable<Tire | null> {
    const url = `${this.BASE_URL}tire/${id}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return this._setMapTire(response.results as TireApiModel);
      })
    );
  }

  createTire(tire: TireCreatedOrUpdate): Observable<Tire | null> {
    const url = `${this.BASE_URL}tire`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, tire, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapTire(response.results as TireApiModel);
      })
    );
  }

  createTires(tires: TireCreatedOrUpdate[]): Observable<string | null> {
    const url = `${this.BASE_URL}tires`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, tires, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return response.results;
      })
    );
  }

  updateTire(id: number, tire: TireCreatedOrUpdate): Observable<Tire | null> {
    const url = `${this.BASE_URL}tire/${id}`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, tire, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapTire(response.results as TireApiModel);
      })
    );
  }

  updateTires(tires: TireUpdated[]): Observable<boolean | null> {
    const url = `${this.BASE_URL}tires`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, tires, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return response.ok;
      })
    );
  }

  deleteTire(id: number): Observable<boolean> {
    const url = `${this.BASE_URL}tire/${id}`;
    const headers = new HttpHeaders();
    return this._http.delete<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return response.ok;
      })
    );
  }
}
