import { inject, Injectable } from '@angular/core';
import { IDepartmentApiService } from './department-api.interface';
import { map, Observable } from 'rxjs';
import { Department } from '../domain/Department.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DepartmentApiModel } from './models/department-api.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsApiService implements IDepartmentApiService {
  private readonly BASE_URL = environment.baseUrl;

  private _http = inject(HttpClient);

  private _mapDepartmentApiToDepartment(
    departmentApi: DepartmentApiModel
  ): Department {
    return {
      id: departmentApi.ID,
      createdAt: departmentApi.CreatedAt,
      updatedAt: departmentApi.UpdatedAt,
      name: departmentApi.name,
      createdBy: departmentApi.createdBy,
      updatedBy: departmentApi.updatedBy,
    };
  }

  private _mapDepartmentsToDepartments(
    departments: DepartmentApiModel[]
  ): Department[] {
    return departments.map((department) =>
      this._mapDepartmentApiToDepartment(department)
    );
  }

  getDepartments(): Observable<Department[]> {
    const url = `${this.BASE_URL}departments`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      map((response) => {
        return this._mapDepartmentsToDepartments(
          response.results as DepartmentApiModel[]
        );
      })
    );
  }

  getDepartmentById(id: number): Observable<Department> {
    const url = `${this.BASE_URL}departments/${id}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      map((response) => {
        return this._mapDepartmentApiToDepartment(
          response.results as DepartmentApiModel
        );
      })
    );
  }

  createDepartment(department: { name: string }): Observable<Department> {
    const url = `${this.BASE_URL}departments`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, department, { headers }).pipe(
      map((response) => {
        return this._mapDepartmentApiToDepartment(
          response.results as DepartmentApiModel
        );
      })
    );
  }

  updateDepartment(department: { name: string }): Observable<Department> {
    const url = `${this.BASE_URL}departments`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, department, { headers }).pipe(
      map((response) => {
        return this._mapDepartmentApiToDepartment(
          response.results as DepartmentApiModel
        );
      })
    );
  }

  deleteDepartment(id: number): Observable<Department> {
    const url = `${this.BASE_URL}departments/${id}`;
    const headers = new HttpHeaders();
    return this._http.delete<ResponseApi>(url, { headers }).pipe(
      map((response) => {
        return this._mapDepartmentApiToDepartment(
          response.results as DepartmentApiModel
        );
      })
    );
  }
}
