import { Injectable, inject } from '@angular/core';
import { IDashboardApiService } from './dashboard.interface';
import { Observable, map, take } from 'rxjs';
import {
  ExchangeFinished,
  ExchangeByType,
  ExchangeByMovement,
  ExchangeAvgByMonth,
  ExchangeAvgByWeek,
  ExchangeByMonth,
  ExchangeByWeek,
  AverageExchangeDuration,
  OperatorWithMostExchanges,
  TrailerWithMostExchanges,
  ExchangeByCustomer,
  ExchangeCompletionRate,
  ExchangeCompletionRateByBranch,
  ExchangeCompletionRateByMonth,
  TruckWithMostExchanges,
} from '../domain/Dashboard.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import {
  AverageExchangeDurationApiModel,
  ExchangeAvgByMonthApiModel,
  ExchangeAvgByWeekApiModel,
  ExchangeByCustomerApiModel,
  ExchangeByMonthApiModel,
  ExchangeByMovementApiModel,
  ExchangeByTypeApiModel,
  ExchangeByWeekApiModel,
  ExchangeCompletionRateApiModel,
  ExchangeCompletionRateByBranchApiModel,
  ExchangeCompletionRateByMonthApiModel,
  ExchangeFinishedApiModel,
  OperatorWithMostExchangesApiModel,
  TrailerWithMostExchangesApiModel,
  TruckWithMostExchangesApiModel,
} from './models/dashboard-api.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService implements IDashboardApiService {
  private readonly BASE_URL = environment.baseUrl;
  private readonly _http = inject(HttpClient);

  getExchangeFinished(): Observable<ExchangeFinished[]> {
    const url = `${this.BASE_URL}dashboard/exchanges-finished`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeFinishedApiModel[] as ExchangeFinished[]
      )
    );
  }

  getExchangeByType(): Observable<ExchangeByType[]> {
    const url = `${this.BASE_URL}dashboard/exchanges-by-type`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeByTypeApiModel[] as ExchangeByType[]
      )
    );
  }

  getExchangeByMovement(): Observable<ExchangeByMovement[]> {
    const url = `${this.BASE_URL}dashboard/exchanges-by-movement`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeByMovementApiModel[] as ExchangeByMovement[]
      )
    );
  }

  getExchangeAvgByMonth(): Observable<ExchangeAvgByMonth[]> {
    const url = `${this.BASE_URL}dashboard/average-exchanges-by-month`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeAvgByMonthApiModel[] as ExchangeAvgByMonth[]
      )
    );
  }

  getExchangeAvgByWeek(): Observable<ExchangeAvgByWeek[]> {
    const url = `${this.BASE_URL}dashboard/average-exchanges-by-week`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeAvgByWeekApiModel[] as ExchangeAvgByWeek[]
      )
    );
  }

  getExchangeByMonth(): Observable<ExchangeByMonth[]> {
    const url = `${this.BASE_URL}dashboard/total-exchanges-by-month`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeByMonthApiModel[] as ExchangeByMonth[]
      )
    );
  }

  getExchangeByWeek(): Observable<ExchangeByWeek[]> {
    const url = `${this.BASE_URL}dashboard/total-exchanges-by-week`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeByWeekApiModel[] as ExchangeByWeek[]
      )
    );
  }

  getAverageExchangeDuration(): Observable<AverageExchangeDuration[]> {
    const url = `${this.BASE_URL}dashboard/average-exchange-duration`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as AverageExchangeDurationApiModel[] as AverageExchangeDuration[]
      )
    );
  }

  getOperatorWithMostExchanges(): Observable<OperatorWithMostExchanges[]> {
    const url = `${this.BASE_URL}dashboard/top-operators`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as OperatorWithMostExchangesApiModel[] as OperatorWithMostExchanges[]
      )
    );
  }

  getTrailerWithMostExchanges(): Observable<TrailerWithMostExchanges[]> {
    const url = `${this.BASE_URL}dashboard/trailer-most-exchanges`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as TrailerWithMostExchangesApiModel[] as TrailerWithMostExchanges[]
      )
    );
  }

  getTruckWithMostExchanges(): Observable<TruckWithMostExchanges[]> {
    const url = `${this.BASE_URL}dashboard/truck-most-exchanges`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as TruckWithMostExchangesApiModel[] as TruckWithMostExchanges[]
      )
    );
  }

  getExchangeByCustomer(): Observable<ExchangeByCustomer[]> {
    const url = `${this.BASE_URL}dashboard/exchanges-by-customer`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeByCustomerApiModel[] as ExchangeByCustomer[]
      )
    );
  }

  getExchangeCompletionRate(): Observable<ExchangeCompletionRate[]> {
    const url = `${this.BASE_URL}dashboard/exchanges-completion-rate`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeCompletionRateApiModel[] as ExchangeCompletionRate[]
      )
    );
  }

  getExchangeCompletionRateByBranch(): Observable<
    ExchangeCompletionRateByBranch[]
  > {
    const url = `${this.BASE_URL}dashboard/exchanges-completion-rate-by-branch`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeCompletionRateByBranchApiModel[] as ExchangeCompletionRateByBranch[]
      )
    );
  }

  getExchangeCompletionRateByMonth(): Observable<
    ExchangeCompletionRateByMonth[]
  > {
    const url = `${this.BASE_URL}dashboard/exchanges-completion-rate-by-month`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map(
        (response) =>
          response.results as ExchangeCompletionRateByMonthApiModel[] as ExchangeCompletionRateByMonth[]
      )
    );
  }
}
