import { InjectionToken } from '@angular/core';
import { TrailerControlReportApiService } from '../trailer-control-report-api.service';

export const HTTP_TRAILER_CONTROL_REPORT_SERVICE = new InjectionToken(
  'TrailerControlReportApiService'
);

export const TRAILER_CONTROL_REPORT_PROVIDER = {
  useClass: TrailerControlReportApiService,
  provide: HTTP_TRAILER_CONTROL_REPORT_SERVICE,
};
