import { inject, Injectable } from '@angular/core';
import { IYardTCApiService } from './yard-api.interface';
import { map, Observable } from 'rxjs';
import { YardTC, YardTCCreateorUpdate } from '../domain/YardTC.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { YardTCApiModel } from './models/yard-tc-api.model';

@Injectable({
  providedIn: 'root',
})
export class YardApiService implements IYardTCApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  private _mapYardApiModelToYardTC(yardApiModel: YardTCApiModel): YardTC {
    return {
      id: yardApiModel.ID,
      createdAt: yardApiModel.CreatedAt,
      updatedAt: yardApiModel.UpdatedAt,
      createdBy: yardApiModel.createdBy,
      updatedBy: yardApiModel.updatedBy,
      name: yardApiModel.name,
    };
  }

  private _mapYardsApiModelToYardsTC(
    yardsApiModel: YardTCApiModel[]
  ): YardTC[] {
    return yardsApiModel.map((yardApiModel) =>
      this._mapYardApiModelToYardTC(yardApiModel)
    );
  }

  getAllYards(): Observable<YardTC[]> {
    const url = `${this.BASE_URL}yard_tc`;
    const headers = new HttpHeaders();
    return this._http
      .get<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapYardsApiModelToYardsTC(response.results as YardTCApiModel[])
        )
      );
  }

  getYardById(yardId: number): Observable<YardTC> {
    const url = `${this.BASE_URL}yard_tc/${yardId}`;
    const headers = new HttpHeaders();
    return this._http
      .get<ResponseApi>(url, { headers })
      .pipe(
        map((response) =>
          this._mapYardApiModelToYardTC(response.results as YardTCApiModel)
        )
      );
  }

  createYard(yard: YardTCCreateorUpdate): Observable<YardTC> {
    const url = `${this.BASE_URL}yard_tc`;
    const headers = new HttpHeaders();
    return this._http
      .post<ResponseApi>(url, yard, { headers })
      .pipe(
        map((response) =>
          this._mapYardApiModelToYardTC(response.results as YardTCApiModel)
        )
      );
  }

  updateYard(id: number, yard: YardTCCreateorUpdate): Observable<YardTC> {
    const url = `${this.BASE_URL}yard_tc/${id}`;
    const headers = new HttpHeaders();
    return this._http
      .put<ResponseApi>(url, yard, { headers })
      .pipe(
        map((response) =>
          this._mapYardApiModelToYardTC(response.results as YardTCApiModel)
        )
      );
  }

  deleteYard(yardId: number): Observable<boolean> {
    const url = `${this.BASE_URL}yard_tc/${yardId}`;
    const headers = new HttpHeaders();
    return this._http
      .delete<ResponseApi>(url, { headers })
      .pipe(map((response) => response.ok));
  }
}
