import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs';

export const accessTokenInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    tap((event) => {
      if (event instanceof HttpResponse) {
        const response: any = event.body;
        if (response?.newAccessToken) {
          // update the access token in the local storage
          localStorage.setItem('token', response.newAccessToken);
        }
      }
    })
  );
};
