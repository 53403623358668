import { inject, Injectable } from '@angular/core';
import { ITrailerControlReportApiService } from './trailer-control-report-api.interface';
import { map, Observable, take } from 'rxjs';
import { QuantitySeal } from '../domain/QuantitySeal.model';
import { QuantityTrailersLoadedEmpty } from '../domain/QuantityTrailersLoadedEmpty.model';
import { SealTrailerYard } from '../domain/SealTrailerYard.model';
import { TrailerControlReport } from '../domain/TrailerControlReport.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TrailerControlReportApiModel } from './models/trailer-control-report-api.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { QuantityTrailersLoadedEmptyApiModel } from './models/quantity-trailers-loaded-empty-api.model';
import { SealTrailerYardApiModel } from './models/seal-trailer-yard-api.model';
import { Positions } from '../domain/Positions.model';

@Injectable({
  providedIn: 'root',
})
export class TrailerControlReportApiService
  implements ITrailerControlReportApiService
{
  private readonly BASE_URL = environment.baseUrl;
  private readonly BASE_URL_MICROSERVICES = environment.baseUrlMicroservices;
  private _http = inject(HttpClient);

  private _trailerControlReportApiModelToTrailerControlReport(
    tcr: TrailerControlReportApiModel
  ): TrailerControlReport {
    return {
      id: tcr.id,
      createdAt: tcr.createdAt,
      createdBy: tcr.createdBy,
      trailerId: tcr.trailerId,
      trailerName: tcr.trailerName,
      isEmpty: tcr.isEmpty,
      isLoaded: tcr.isLoaded,
      isUnfit: tcr.isUnfit,
      details: tcr.unfitDetails,
      typeSeal: tcr.typeSeal,
      typeSealName: tcr.typeSealName,
      sealNumber: tcr.sealNumber,
      yardName: tcr.yardName,
      ubication: tcr.ubication,
      trailerOwnerId: tcr.trailerOwnerId,
      trailerOwnerName: tcr.trailerOwnerName,
      arrivalDate: tcr.arrivalDate,
      daysPassed: tcr.daysPassed,
      log: tcr.log,
    };
  }

  private _trailerControlReportApiModelToTrailerControlReports(
    tcrs: TrailerControlReportApiModel[]
  ): TrailerControlReport[] {
    return tcrs.map((tcr) =>
      this._trailerControlReportApiModelToTrailerControlReport(tcr)
    );
  }

  private _quantitySealApiModelToQuantitySeal(qs: QuantitySeal): QuantitySeal {
    return {
      count: qs.count,
      name: qs.name,
    };
  }

  private _quantitySealsApiModelToQuantitySeals(
    qss: QuantitySeal[]
  ): QuantitySeal[] {
    return qss.map((qs) => this._quantitySealApiModelToQuantitySeal(qs));
  }

  private _quantityTrailersLoadedEmptyApiModelToQuantityTrailersLoadedEmpty(
    qtle: QuantityTrailersLoadedEmptyApiModel
  ): QuantityTrailersLoadedEmpty {
    return {
      loadedCount: qtle.loadedCount,
      emptyCount: qtle.emptyCount,
    };
  }

  private _sealTrailerYardApiModelToSealTrailerYard(
    sty: SealTrailerYard
  ): SealTrailerYard {
    return {
      typeSealName: sty.typeSealName,
      yardName: sty.yardName,
      trailerCount: sty.trailerCount,
    };
  }

  private _sealTrailerYardApiModelToSealTrailerYards(
    stys: SealTrailerYard[]
  ): SealTrailerYard[] {
    return stys.map((sty) =>
      this._sealTrailerYardApiModelToSealTrailerYard(sty)
    );
  }

  getTrailerControlReport(date: string): Observable<TrailerControlReport[]> {
    const url = `${this.BASE_URL}trailer-control/report/${date}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.results) return [];
        return this._trailerControlReportApiModelToTrailerControlReports(
          response.results as TrailerControlReportApiModel[]
        );
      })
    );
  }

  getQuantitySealReport(date: string): Observable<QuantitySeal[]> {
    const url = `${this.BASE_URL}trailer-control/report/seals/${date}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.results) return [];
        return this._quantitySealsApiModelToQuantitySeals(
          response.results as QuantitySeal[]
        );
      })
    );
  }

  getQuantityTrailersLoadedEmptyReport(
    date: string
  ): Observable<QuantityTrailersLoadedEmpty> {
    const url = `${this.BASE_URL}trailer-control/report/loaded-empty/${date}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) =>
        this._quantityTrailersLoadedEmptyApiModelToQuantityTrailersLoadedEmpty(
          response.results as QuantityTrailersLoadedEmptyApiModel
        )
      )
    );
  }

  getSealTrailerYardReport(date: string): Observable<SealTrailerYard[]> {
    const url = `${this.BASE_URL}trailer-control/report/seals-trailers-yard/${date}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.results) return [];
        return this._sealTrailerYardApiModelToSealTrailerYards(
          response.results as SealTrailerYardApiModel[]
        );
      })
    );
  }

  getTrailerControlReportExcel(date: string): Observable<string> {
    const url = `${this.BASE_URL_MICROSERVICES}inventory-report/${date}`;
    const headers = new HttpHeaders().set('skiptoken', 'true');
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.results) return '';
        return response.results as string;
      })
    );
  }

  notifyTrailersWithoutArrivalDate(date: string): Observable<string> {
    const url = `${this.BASE_URL}trailer-control/notify/${date}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.results) return '';
        return response.results as string;
      })
    );
  }

  getPositionsTrailersControl(date: string): Observable<Positions[]> {
    const url = `${this.BASE_URL}trailer-control/report/positions/${date}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.results) return [];
        return response.results as Positions[];
      })
    );
  }
}
