import { inject, Injectable } from '@angular/core';
import { IUbicationApiService } from './ubication-api.interface';
import { map, Observable, take } from 'rxjs';
import { UbicationCreate } from '../domain/Ubication-create.model';
import { Ubication } from '../domain/Ubication.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UbicationApiModel } from './models/ubication-api.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';

@Injectable({
  providedIn: 'root',
})
export class UbicationApiService implements IUbicationApiService {
  private readonly BASE_URL = environment.baseUrl;
  private readonly _http = inject(HttpClient);

  private _setMapToUbication(ubication: UbicationApiModel): Ubication {
    return {
      id: ubication.ID,
      createdAt: ubication.CreatedAt,
      updatedAt: ubication.UpdatedAt,
      deletedAt: ubication.DeletedAt,
      createdBy: ubication.createdBy,
      updatedBy: ubication.updatedBy,
      // yard: ubication.yard,
      ubication: ubication.ubication,
      yardId: ubication.yardTCID,
      yardName: ubication.name,
    };
  }

  private _setMapToUbications(ubications: UbicationApiModel[]): Ubication[] {
    return ubications.map((ubication) => this._setMapToUbication(ubication));
  }

  getUbications(): Observable<Ubication[]> {
    const url = `${this.BASE_URL}ubication`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) =>
        this._setMapToUbications(response.results as UbicationApiModel[])
      )
    );
  }

  getUbication(id: number): Observable<Ubication> {
    const url = `${this.BASE_URL}ubication/${id}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) =>
        this._setMapToUbication(response.results as UbicationApiModel)
      )
    );
  }

  getUbicationsByYard(yardId: number): Observable<Ubication[]> {
    const url = `${this.BASE_URL}ubication/yard/${yardId}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) =>
        this._setMapToUbications(response.results as UbicationApiModel[])
      )
    );
  }

  createUbication(ubication: UbicationCreate): Observable<Ubication> {
    const url = `${this.BASE_URL}ubication`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, ubication, { headers }).pipe(
      take(1),
      map((response) =>
        this._setMapToUbication(response.results as UbicationApiModel)
      )
    );
  }

  updateUbication(
    id: number,
    ubication: UbicationCreate
  ): Observable<Ubication> {
    const url = `${this.BASE_URL}ubication/${id}`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, ubication, { headers }).pipe(
      take(1),
      map((response) =>
        this._setMapToUbication(response.results as UbicationApiModel)
      )
    );
  }

  deleteUbication(id: number): Observable<boolean> {
    const url = `${this.BASE_URL}ubication/${id}`;
    const headers = new HttpHeaders();
    return this._http.delete<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => response.ok)
    );
  }
}
