import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthUseCaseService } from '../../../auth/application/auth-use-case.service';

export const headersInterceptor: HttpInterceptorFn = (req, next) => {
  const _authService = inject(AuthUseCaseService);
  const token = _authService.token;

  if (req.headers.get('skiptoken')) {
    const headers = new HttpHeaders();
    const newReq = req.clone({ headers });
    return next(newReq);
  }

  const authRequest = req.clone({
    setHeaders: {
      Authorization: token,
    },
  });

  return next(authRequest);
};
